.allDiv {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    background-color: #fafafa;
    height: 80px;
}

.smallNavDiv {
    height: 60px;
}

.mobileLogoDiv {
    display: flex;
    justify-content: center;
}

.logo {
    width: 70px;
    height: 70px;
    margin: 5px 0 5px 0;
}

.mobileLogo {
    width: 50px;
    height: 50px;
    margin: 5px 0 5px 0;
}

.content {
    display: flex;
    justify-content: space-between;
}

.buttons {
    display: flex;
    height: 80px;
}

.smallButtons {
    height: 60px;
}

.buttons:hover {
}

.link {
    border-bottom: 3px solid transparent;
    display: flex;
    flex: 1;
    align-items: center;
}

.button {
    color: #333333;
    font-weight: 700;
    padding: 0 15px 0 15px;
    text-transform: uppercase;
}

.link:hover {
    border-bottom: 3px solid #101579;
}

.link:hover .button {
    color: #004eff;
}

.dropdown {
    border-bottom: 3px solid transparent;
    display: flex;
    flex: 1;
    align-items: center;
}

.dropdown:hover {
    border-bottom: 3px solid #101579;
}

.dropButton {
    composes: button;
}

.dropContent {
    position: absolute;
    background-color: #333333;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    top: 80px;
    left: 0;
    right: 0;
    z-index: 100;
    height: 0;
    transition: height 0.1s ease-in 0.3s;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.smallNavDropContent {
    top: 60px;
}

.dropdown:hover .dropContent {
    height: 50px;
    transition: height 0.1s ease;
}

.dropdown:hover .subButton {
    opacity: 1;
    transition: opacity 400ms ease;
}

.subLink {
    display: flex;
    align-items: center;
}

.subLink:hover .subButton {
    color: #004eff;
}

.subButton {
    opacity: 0;
    color: #fafafa;
    padding: 0 15px 0 15px;
    transition: color 0.5s ease, opacity 0.5s ease;
    text-transform: uppercase;
    font-weight: 700;
}

.menuContent {
    display: flex;
    flex-direction: column;
}
