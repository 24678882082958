.all {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.title {
    font-weight: 900;
    font-size: 30px;
    color: #101579;
    margin: 10px;
}

.text {
    font-size: 20px;
    font-weight: 700;
    color: #666;
}
