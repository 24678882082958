@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body,
html,
button,
input,
select,
textarea {
    font-family: 'Open Sans', sans-serif, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #666666;
    margin: 0;
    font-size: 13px;
    line-height: 20px;
}

html {
    /* width: 100%; */
    /* height: 100%; */
    background-color: #f1f1f1;
    overflow: auto;
}

body {
    /* width: 100%;
    height: 100%; */
    background-color: #f1f1f1;
}

#root {
    width: 100%;
    height: 100%;
    background-color: #f1f1f1;
}

/**
 * Address `font-family` inconsistency between `textarea` and other form
 * elements.
 */
html,
button,
input,
select,
textarea {
    font-family: sans-serif;
}

/* H1&H3  for page titles */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Raleway';
    font-style: normal;
    margin: 0 0 20px 0;
    font-weight: 400;
    color: #4b4b4b;
}

h1 {
    font-size: 26px;
    line-height: 35px;
}

h2 {
    font-size: 24px;
    line-height: 30px;
}

h3 {
    font-size: 22px;
    line-height: 26px;
}

h4 {
    font-size: 18px;
    line-height: 22px;
}

h5 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0px;
}

h6 {
    font-size: 14px;
    line-height: 20px;
}

a {
    color: #101579;
}
a:hover {
    color: #004eff;
}

a {
    text-decoration: none;
}
a:focus {
    outline: none;
}
a:hover {
    text-decoration: none;
}

p {
    font-family: 'Open Sans', sans-serif, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 20px;
}

.ui.button {
    /*border-radius: 0px;*/
    margin: 0;
}

.ui.button:hover {
    /*border-radius: 0px;*/
}
/* Primary buttons */
.ui.primary.button,
.ui.primary.buttons .button {
    background-color: #101579;
    color: #fff;
}

.ui.primary.button:focus {
    background-color: #101579;
    color: white;
}

.ui.primary.button:hover,
.ui.primary.button:active {
    background-color: #2b2bda;
    color: white;
}

/* Button next to input */
.ui.action.input .button {
    background-color: #101579;
    color: white;
}

/* Container */
@media only screen and (max-width: 767px) {
    .ui.container {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0;
        padding-right: 0;
    }
}

/* Tables */
.ui.table {
    border-radius: 0px;
}

.ui.table thead tr:first-child > th:first-child {
    border-radius: 0px;
}

.ui.table thead tr:first-child > th:last-child {
    border-radius: 0px;
}

.ui.table thead th {
    background: #101579;
    color: white;
}

.ui.celled.table tr th {
    border-left: 1px solid rgba(34, 36, 38, 0.1);
}

/* Accordions */
.ui.styled.accordion,
.ui.styled.accordion .accordion {
    border-radius: 0;
    background: none;
}

.ui.styled.accordion .accordion .content,
.ui.styled.accordion .content {
    background: transparent;
    padding: 0.5em 1em;
}

.ui.styled.accordion .active.title {
    color: #101579;
}

/* modal */
.modal {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
}

/* Slider */
.slider-list {
    touch-action: initial !important;
}

/*
** react-burger-menu css
*/
/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 26px;
    height: 20px;
    right: 25px;
    top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    display: none;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #333333;
}

/* General sidebar styles */
.bm-menu {
    background: #fafafa;
    /* padding-top: 2.5em; */
    font-size: 1.15em;
    overflow-y: scroll;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #fafafa;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

/*
** React quill editor
*/
.ql-editor {
    min-height: 300px;
}

.ql-editor strong {
    font-weight: 700;
}
