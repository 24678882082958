.item {
    padding-top: 50px;
}

.row {
    display: flex;
}

.button {
    background-color: transparent;
    color: #333333;
    display: flex;
    flex: 1;
    height: 4em;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    padding-left: 50px;
}

.activeButton {
    composes: button;
    background-color: #333333;
    color: #fafafa;
}

.rect {
    width: 5px;
    height: auto;
    background-color: transparent;
}

.selectRect {
    composes: rect;
    background-color: #2b2bda;
}
