.all {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: auto;
    min-height: 100%;
    width: 100%;
    position: absolute;
}

.allContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}

.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 80px;
}

.contentMobile {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 60px;
}
