.all {
    width: 100%;
    background-color: #303030;
}

.text {
    padding: 20px;
    color: #afafaf;
    text-align: center;
}
